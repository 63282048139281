<template>
   <main>
      <section class="slider__area pt-30 pb-50 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-xl-2 custom-col-2 d-none d-xl-block">
                  <div class="cat__menu-wrapper">
                     <div class="cat-toggle">
                        <button type="button" class="cat-toggle-btn"><i class="fas fa-bars"></i> Categorias</button>
                        <div class="cat__menu">
                           <nav id="mobile-menu">
                              <ul>
                                 <li v-for="(categoria, index) in listaCategorias" :key="index">
                                    <a href="javascript:;" class="hover" :class="pesquisa.categoria == categoria.categoria ? 'bg-selected' : ''" :title="'Buscar por '+ categoria.categoria" @click="pesquisar(categoria.categoria)">
                                       {{ categoria.categoria }}
                                    </a>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xl-10 custom-col-10 col-lg-12">
                  <div class="row">
                     <div class="col-12 mb-1 d-none d-xl-block" v-if="pesquisa.marca != null">
                        <button type="button" class="search-title">
                           <i class="fas fa-bars"></i> {{ pesquisa.marca }}
                        </button>
                     </div>
                     <div class="col-12 mb-1 d-none d-xl-block" v-if="pesquisa.listaSubcategorias != undefined">
                        <button type="button" class="search-title">
                           <i class="fas fa-bars"></i> {{ pesquisa.categoria }}
                           <small class="text-secondary font-10 ms-1">Subcategorias</small>
                        </button>
                     </div>
                     <div class="col-12 mb-3 d-none d-xl-block" v-if="pesquisa.listaSubcategorias != undefined">
                        <div class="cards-sm">
                           <subcategoria v-for="(subcategoria, index) in pesquisa.listaSubcategorias" :key="index" :categoria="pesquisa.categoria" :subcategoria="subcategoria" />
                        </div>
                     </div>

                     <div class="col-12">
                        <div class="cards">
                           <produto class="product__electronic-item" v-for="(produto, index) in pesquisa.resultado" :key="index" :produto="produto" :page="'PESQUISA'" />
                        </div>
                     </div>

                     <div class="col-12" v-if="pesquisa.resultado.length == 0">
                        <div class="text-center my-5 w-100">Nenhum produto encontrado</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import produto from '@/components/home/Produto.vue'
import subcategoria from '@/components/produtos/Subcategoria.vue'

export default {
	name: 'Produtos',
   computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
			pesquisa: state => state.pesquisa
		})
	},
   components: {
		produto, subcategoria
	},
   methods: {
      pesquisar : function (categoria) {
         this.$store.dispatch('pesquisar', {'valor': '', 'categoria': categoria, 'subcategoria': 'Todas subcategorias', 'marca': null})
      }
   },
   mounted() {
      Vue.nextTick(function() {
         $('.cat-toggle-btn').on('click', function () {
            $('.cat__menu').slideToggle(500);
         });
      }.bind(this));
   }
}

</script>

<style scoped>

.search-title {
   background: #ffffff;
   color: #222;
   border: 1px solid rgba(0,0,0,.125);
   border-radius: 0.25rem;
   font-size: 14px;
   text-transform: uppercase;
   font-weight: 500;
   height: 61px;
   line-height: 61px;
   width: 100%;
   text-align: left;
   padding-left: 20px;
}

.search-title i {
   margin-right: 10px;
}

</style>